import React from 'react';
import Link from 'gatsby-link';
import tomImage from '../../../img/tom.png';
import CV from './CV.pdf';
import './OrtomBrief.scss';

const OrtomBrief = () => (
  <div className="container mobile-container">
    <div className="columns is-fullhd">
      <div className="column is-half tomImage">
        <img className="" src={tomImage} alt="Tom man standing proudly" style={{ height: 'auto', width: '100%' }} />
      </div>
      <div className="column is-half textWithButton ">
        <div>
          <h3>Ortom has consistently built successful data products for innovative companies.</h3>
          <br />
          <p>
          Ortom is a data science consultancy run by Manchester based machine learning expert Tom Liptrot.
          </p>
          <br />
          <p>
          Tom has skills in data science, machine learning, business consultancy and statistics. 
          He brings astute commercial experience having worked in sectors including healthcare, pharma, retail and software.
          </p>
          <br />
          <p>
          Tom uses Python, R and C++, depending  on the job at hand. He is at home on the cloud (AWS, GCP, Azure). 
          He has particular expertise in Natural Language Processing (NLP), predictive machine learning, healthcare, 
          forecasting, exploratory data analysis and inventory optimisation.
          </p>

        </div>
        <div style={{ margin: '32px 0' }} />
        <a href={CV} download>
          <button type="button " className=" button button-trans-arrow is-outlined is-rounded is-fullwidth OTButton-secondary OTButton">Download my CV</button>
        </a>
      </div>
    </div>
    <div className="gap" />
  </div>
);

export default OrtomBrief;

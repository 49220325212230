import React from 'react';
import { Link } from 'gatsby';
import introImage from '../../../img/introImage.png';
import './Intro.sass';

const Intro = ({
  tagline, paragraph, paragraph2, buttonText, image,
}) => (
  <div className="container mobile-container">
    <div className="intro">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <h3 className="intro--text">
            {tagline}
          </h3>
        </div>
      </div>
      <div className="columns intro-content ">
        <div className="column is-6">
          <img alt="orange swirls" src={introImage} style={{ width: '100%' }} />
        </div>
        <div className="column is-6 textWithButton ">
          <div className="introParagraph">
            <p>
              {paragraph}
            </p>
            <br />
            <p>
              {paragraph2}
            </p>
          </div>
          <Link
            to="/services"
          >
            <button type="button" className="button is-dark is-rounded is-fullwidth intro--button OTButton">{buttonText}</button>
          </Link>
        </div>
      </div>
      <div className="gap" />
    </div>
  </div>
);

export default Intro;

import React from 'react';
import Sketch from './Sketch';
import Tagline from './Tagline';
import Intro from './Intro/Intro';
import CaseStudies from './CaseStudies/CaseStudies';
import Testimonials from './Testimonials/Testimonials';
import OrtomBrief from './OrtomBrief/OrtomBrief';
import Contact from './Contact/Contact';
import SignUp from './SignUp/SignUp';

const Landing = (props) => (
  <div>
    {!process.env.HIDE_SKETCH && <Sketch />}
    <Tagline />
    <Intro
      tagline={props.frontmatter.intro.tagline}
      paragraph={props.frontmatter.intro.paragraph}
      paragraph2={props.frontmatter.intro.paragraph2}
      buttonText={props.frontmatter.intro.buttonText}
      image={props.frontmatter.intro.image}
    />
    <CaseStudies />
    <div className="gap" />
    <Testimonials testimonials={props.frontmatter.testimonials} />
    <OrtomBrief />
  </div>
);

export default Landing;

const GOL = (p) => {
  let columns;
  let rows;
  let board;
  let next;
  // let permenantColors;

  // configure sketch size
  const widthPercentage = 100 / 100;
  const height = p.windowHeight * 4 / 10;
  const w = 10;

  const fullCell = p.color(237, 66, 51);
  const mousedOver = p.color(33, 34, 38);
  const emptyCell = p.color(234, 233, 229);

  const createArray = () => {
    // create permenenantly on cells
    const newArray = new Array(columns);
    for (let i = 0; i < columns; i++) {
      newArray[i] = new Array(rows);
    }

    return newArray;
  };

  p.setup = () => {
    // change this to change speed of animation
    p.frameRate(10);

    // create canvas of width and height
    const c = p.createCanvas(p.windowWidth * widthPercentage, height);

    // When a click happens reset the sketch
    c.mousePressed(() => p.init());

    // Calculate columns and rows
    columns = p.floor(p.width / w);
    rows = p.floor(p.height / w);

    // Create 2D Array from rows and columns
    board = createArray();

    // create Next array state
    next = createArray();

    // create permenenantly on cells
    // permenantColors = createArray();

    // Set line thickness between cells
    p.strokeWeight(0);

    // on first draw, randomise the board
    p.init();
  };

  p.draw = () => {
    // 95 / 100 * cell width
    // if (p.mouseX > 0 && p.mouseX / w < p.floor(p.windowWidth * widthPercentage / w)
    //     && p.mouseY > 0 && p.mouseY < height) {
    //   permenantColors[p.floor(p.mouseX / w)][p.floor(p.mouseY / w)] = 1;
    // }

    p.generate();

    // timer tests
    // if (p.frameCount === 100) {
    //   console.timeEnd('Sketch initialised');
    // }

    for (let i = 0; i < columns; i++) {
      const thisCol = board[i];
      // const thisColPerm = permenantColors[i];

      for (let j = 0; j < rows; j++) {
        // if ((thisColPerm[j] == 1)) p.fill(mousedOver);
        if ((thisCol[j] == 1)) p.fill(fullCell);
        else p.fill(emptyCell);

        p.rect(i * w, j * w, w - 0, w - 0);
      }
    }
  };

  // Fill board randomly
  p.init = () => {
    console.time('Sketch initialised');
    for (let i = 0; i < columns; i++) {
      const thisCol = board[i];
      const thisColNext = next[i];

      for (let j = 0; j < rows; j++) {
        // Lining the edges with 0s
        if (i == 0 || j == 0 || i == columns - 1 || j == rows - 1) thisCol[j] = 0;

        // Filling the rest randomly
        else thisCol[j] = (p.random() < 0.1);
      }
    }
  };

  p.windowResized = () => {
    p.setup();
  };

  // The process of creating the new generation
  p.generate = () => {
    // Loop through every spot in our 2D array and check spots neighbors
    for (let x = 1; x < columns - 1; x++) {
      const thisCol = board[x];
      const thisColNext = next[x];
      // const thisColPerm = permenantColors[x];

      for (let y = 1; y < rows - 1; y++) {
        // Add up all the states in a 3x3 surrounding grid
        let neighbors = 0;
        for (let i = -1; i <= 1; i++) {
          for (let j = -1; j <= 1; j++) {
            neighbors += board[x + i % rows][y + j];
          }
        }

        // A little trick to subtract the current cell's state since
        // we added it in the above loop
        neighbors -= thisCol[y];

        // Rules of Life
        if ((thisCol[y] == 1) && (neighbors < 2)) thisColNext[y] = 0; // Loneliness
        else if ((thisCol[y] == 1) && (neighbors > 3)) thisColNext[y] = 0; // Overpopulation
        else if ((thisCol[y] == 0) && (neighbors == 3)) thisColNext[y] = 1; // Reproduction
        // else if ((thisColPerm[y] == 1)) thisColNext[y] = 1; // permenant colors rule
        else thisColNext[y] = thisCol[y]; // Stasis
      }
    }

    const temp = board;
    // Swap!
    board = next;
    next = temp;
  };
};
export default GOL;

import React from 'react';

const Tagline = () => (
  <div className="container is-fluid mobile-container ">
    {/* INE LEARNING HELP FOR STARTUPS AND SCALEUPS */}
    <div>
      <h1>
        A
        <span className="title--techfont">RT</span>
        IFICIAL
        <br />
        INT
        <span className="title--techfont">E</span>
        LLI
        <span className="title--techfont">G</span>
        ENCE
        <span className="title--techfont"> A</span>
        ND
        <br />
        DATA
        <span className="title--techfont"> S</span>
        ERVICES
        <br />
        FOR
        T
        <span className="title--techfont">E</span>
        CH FIR
        <span className="title--techfont">M</span>
        S
      </h1>
    </div>
  </div>
);

export default Tagline;

import React, { useRef } from 'react';
import './testimonials.sass';
import Slider from 'react-slick';
import leftArrow from '../../../img/leftArrow.png';
import rightArrow from '../../../img/rightArrow.png';
import quote from '../../../img/quote.png';

function Testimonials(props) {
  const sliderRef = useRef();

  const gotoNext = () => {
    sliderRef.current.slickNext();
  };

  const gotoPrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div
      className="container"
    >
      <div className="small-gap" />
      <div className="columns testimonials">
        <div className=" column is-10 testimonials--left">
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <Slider ref={sliderRef} arrows={false}>
            {props.testimonials.map((testimonial) => (
              <div key={testimonial.author}>
                <div className="small-gap" />
                <img
                  src={quote}
                  alt="quote"
                  style={{ width: '120px', margin: '32px' }}
                />
                <div className="testimonials--text">
                  <h5>{testimonial.quote}</h5>
                  <div className="testimonials--author">
                    <p className="orange">{testimonial.author}</p>
                    <p>{testimonial.role}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className="column is-2 center testimonials--controls">
          <button className="button is-fullwidth testimonials--controls-right testimonials--control" onClick={gotoNext}>
            <img
              src={rightArrow}
              alt="right arrow"
              style={{ width: '60px' }}
            />
          </button>
          <button className="button is-fullwidth testimonials--controls-left testimonials--control" onClick={gotoPrev}>
            <img
              src={leftArrow}
              alt="left arrow"
              style={{ width: '60px' }}
            />
          </button>
        </div>
      </div>
      <div className="gap" />
    </div>
  );
}

export default Testimonials;

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import './CaseStudies.scss';

export default () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "case-study" }
              featuredpost: { eq: true }
            }
          }
          sort: { fields: frontmatter___date, order: DESC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              client
              featuredimage {
                childImageSharp {
                  fluid(maxWidth: 511, maxHeight: 427, quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `,
  );

  return (
    <>
      <div className="caseStudies">
        <div className="container mobile-container">
          <h2>
            C
            <span className="coolfont">A</span>
            SE ST
            <span className="coolfont">U</span>
            DIES
          </h2>

          <div className="columns is-mobile scroll-x caseStudies-scroll">
            {allMarkdownRemark.nodes.map((caseStudy) => (
              <div className="caseStudy column is-four-fifths-mobile is-one-third-tablet" key={caseStudy.frontmatter.title}>
                {caseStudy.frontmatter.featuredimage && (
                  <Link to={caseStudy.fields.slug}>
                    <Img
                      fluid={
                        caseStudy.frontmatter.featuredimage.childImageSharp.fluid
                      }
                      alt=""
                    />
                  </Link>
                )}
                <p>{caseStudy.frontmatter.client}</p>
                <h5>{caseStudy.frontmatter.title}</h5>
                <Link className="button button-casestudy" to={caseStudy.fields.slug}>
                  <p>
                    <span>View</span>
                  </p>
                </Link>
              </div>
            ))}
          </div>
          <div className="small-gap" />
          <Link
            to="/caseStudies/"
            className="button is-rounded is-fullwidth OTButton OTButton-primary-isdark"
          >
            View more cases
          </Link>
        </div>
      </div>
    </>
  );
};
